<template>
  <section>
    <div class="row q-col-gutter-y-lg">
      <div class="col-12">
        <q-input
          v-model="checkoutStore.customerNotes"
          :rules="[
            (val) =>
              !val?.length || val.length <= 250
              || 'Order Notes must be less than 250 characters.',
          ]"
          :disable="checkoutStore.status == 'pending'"
          :dense="screen.lt.lg"
          label="Order Notes (optional)"
          hint="Have any special requests or questions about your order?"
          type="textarea"
          maxlength="250"
          counter
        />
      </div>
      <div class="col-12">
        <q-item
          v-for="(option, index) in options"
          :key="index"
          v-ripple
          tag="label"
        >
          <q-item-section
            :avatar="screen.gt.md"
            side
            top
          >
            <q-checkbox
              v-model="terms"
              :val="option.value"
              :dense="screen.lt.lg"
              color="positive"
            />
          </q-item-section>
          <q-item-section>
            <q-item-label>{{ option.label }}</q-item-label>
            <q-item-label
              v-if="option.caption"
              caption
            >
              {{ option.caption }}
            </q-item-label>
          </q-item-section>
        </q-item>
      </div>
    </div>

    <q-stepper-navigation>
      <q-btn
        :disable="!canContinue"
        :loading="checkoutStore.status == 'pending'"
        :label="btnLabel"
        color="primary"
        size="lg"
        style="min-width: 125px"
        square
        @click="checkoutStore.submit()"
      />
    </q-stepper-navigation>
  </section>
</template>

<script lang="ts" setup>
interface Option {
  label: string;
  value: string;
  required?: boolean;
  caption?: string;
}

const checkoutStore = useCheckoutStore();
const quoteStore = useQuoteStore();
const cartStore = useCartStore();
const { screen } = useQuasar();
const { $kount } = useNuxtApp();

const terms = ref<string[]>(['newsletter']);

const options = computed(() => {
  const stack: Option[] = [
    {
      label: 'Send new products and deals to my inbox.',
      value: 'newsletter',
      caption: 'Optional',
    },
  ];

  if (quoteStore.data?.requiresFflDealer && !checkoutStore.fflDealer) {
    stack.push({
      label:
        'I will provide FFL information to KYGUNCO so my firearm order(s) can be fulfilled.',
      value: 'ffl',
      required: true,
    });
  }

  if (cartStore.data?.items?.some(i => i.isAge21Restricted)) {
    stack.push({
      label: 'I am 21 years of age or older.',
      value: 'age_21',
      required: true,
    });
  }

  stack.push({
    label: 'I can confirm I have read and agree with the Terms and Conditions.',
    value: 'terms',
    required: true,
  });

  return stack;
});

const accepted = computed(() =>
  options.value
    .filter(o => o.required)
    .every(o => terms.value.includes(o.value)),
);

const btnLabel = computed(() =>
  checkoutStore.paymentMethod == 'Financing'
    ? 'Continue To Financing'
    : 'Submit',
);

const canContinue = computed(() => accepted.value && $kount.ready.value);
</script>
